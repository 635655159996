import React, { useEffect, useMemo } from 'react';
import './PreOrders.scss';
import { CharlesTitle } from '@abm-international/react-components';
import { useLocation } from 'react-router-dom';
import { useRestrictedContent } from '../../libraries/hooks';
import ForbiddenError from '../../components/Error/ForbiddenError/ForbiddenError';
import { t } from '../../libraries/i18n';
import { PERMISSIONS } from '../../libraries/permissions';
import { useDispatch, useSelector } from 'react-redux';
import {
	selectCustomerId,
	selectIsPreOrdersDataFetching,
	selectPreOrdersActions,
	selectNavigationItems,
	selectCanPreOrderActionsSubmit,
	selectShowPreOrderSubmittedWarning,
	selectLocale,
	selectPromos
} from '../selectors';
import { PreOrderAction } from '../../types/preOrders';
import { Permission } from '../../types/permissions';
import Spinner from '../../components/spinner/spinner';
import {
	fetchPreOrdersDataIfNeeded, PREORDERS_ACTION_SUBMITTED_WARNING_DISMISS
} from './preOrdersActions';
import {
	fetchCustomerProductsIfNeeded,
	fetchHistoryIfNeeded,
	fetchMetadataIfNeeded,
	fetchPointsIfNeeded,
	fetchProductsIfNeeded,
	fetchPromosIfNeeded
} from '../products/productsActions';
import PreOrder from './PreOrder/PreOrder';
import DateHelper from '../../libraries/dateHelper';
import { useParams } from 'react-router';
import useCustomerRoute from '../../libraries/hooks/useCustomerRoute';
import { PortalModal } from '@abm-international/react-components';
interface Props {
	requiredPermission: Permission
}

type preOrderParams = {
	type: string;
};

function PreOrders(props: Props) {
	useCustomerRoute();
	const location = useLocation();
	const dispatch = useDispatch();
	const [RestrictedContent, RestrictedContentError] = useRestrictedContent(props.requiredPermission);
	const locale: 'nl' | 'fr' = useSelector(selectLocale);
	const { type } = useParams<preOrderParams>();

	const customerId = useSelector(selectCustomerId);
	const poActions = useSelector(state => selectPreOrdersActions(state, type));
	const isFetching = useSelector(state => selectIsPreOrdersDataFetching(state, type));
	const navItems = useSelector(selectNavigationItems);
	const navTitle = useMemo(() => {
		const navItem = navItems?.find((ni: any) => ni.href === location.pathname);
		if (!navItem) return t('preorders.title');

		return t(`nav.${navItem.title}`);
	}, [navItems, location.pathname]);

	const canSubmitActions = useSelector(state => selectCanPreOrderActionsSubmit(state, type, poActions));
	const anySubmittable = canSubmitActions.some(action => action.canSubmit, 0);

	const showSubmittedWarning = useSelector(state => selectShowPreOrderSubmittedWarning(state, type));

	// Load necessary data if needed
	useEffect(() => {
		dispatch(fetchProductsIfNeeded(customerId));
		dispatch(fetchPromosIfNeeded(customerId));
		dispatch(fetchCustomerProductsIfNeeded(customerId));
		dispatch(fetchMetadataIfNeeded(customerId));
		dispatch(fetchPreOrdersDataIfNeeded(type));
		dispatch(fetchHistoryIfNeeded(customerId));
		dispatch(fetchPointsIfNeeded());
	}, [dispatch, customerId, type]);

	const sortByEndTime = (a: PreOrderAction, b: PreOrderAction) => new Date(a.available.end).getTime() - new Date(b.available.end).getTime();
	/* eslint-disable-next-line @typescript-eslint/no-unused-vars */
	const sortByDeliveryWeek = (a: PreOrderAction, b: PreOrderAction) => {
		const aDelivery = a.delivery.split('.');
		const bDelivery = b.delivery.split('.');
		const aDate = DateHelper.getDateOfISOWeek(aDelivery[1], aDelivery[0]);
		const bDate = DateHelper.getDateOfISOWeek(bDelivery[1], bDelivery[0]);
		return aDate.getTime() - bDate.getTime();
	};

	const renderActions = (poActions: Array<PreOrderAction>) => poActions
		.sort(sortByEndTime)
		.map(poAction => <PreOrder key={poAction.group} action={poAction} preOrderType={type} />);

	const renderSubmitWarning = () => {
		const submittedAction = poActions.find(a => a.group === showSubmittedWarning);

		return (
			<PortalModal close={() => dispatch({ type: PREORDERS_ACTION_SUBMITTED_WARNING_DISMISS, preOrderType: type })}>
				<div className='PreOrders__submitted-warning'>
					<p>
						{t('preorders.label_submitted_warning_1', submittedAction?.description?.[locale])}
					</p>
					<p>
						{t('preorders.label_submitted_warning_2')}
						<ul>
							{canSubmitActions.filter(a => a.canSubmit).map(a => {
								const action = poActions.find(action => action.group === a.group);
								return <li>{action?.description?.[locale]}</li>;
							})}
						</ul>
					</p>
				</div>
			</PortalModal>
		);
	};

	return (
		<div className='PreOrders'>
			<CharlesTitle alt>{navTitle}</CharlesTitle>
			<RestrictedContent>
				{isFetching && poActions?.length <= 0 && <Spinner />}
				{!isFetching && poActions?.length <= 0 && t('preorders.label_no_items_found')}
				{poActions?.length > 0 && renderActions(poActions)}
				{anySubmittable && showSubmittedWarning && renderSubmitWarning()}
			</RestrictedContent>
			<RestrictedContentError>
				<ForbiddenError permissionName={t(`permissions.${PERMISSIONS.PREORDERS}`)} />
			</RestrictedContentError>
		</div>
	);
}

export default PreOrders;
