/* eslint-disable camelcase */
import fetch from 'cross-fetch';
import config from './../config';
import { logger } from './logger/logger';
import { t } from './../libraries/i18n';
import DateHelper from './dateHelper';
import semver from 'semver';
import { Setting } from '../types/settings';
import productHelper from './productHelper';

export const document = async (documentId, customerId, type, body, name) => {
	const options = { body: JSON.stringify(body), credentials: 'include', method: 'POST' };
	const endpoint = `customers/${customerId}/documents/${documentId}?output=${type}&download`;
	const response = await fetch(`${config.API_URL}/api/${endpoint}`, options);

	const contentType = response.headers.get('content-type').replace('; charset=UTF-8', '');

	if (contentType === 'text/html') {
		const binary = await response.blob();
		return { error: await binary.text() };
	}

	if (contentType === 'application/json') {
		return await response.json();
	}

	const binary = await response.blob();
	const url = window.URL.createObjectURL(binary);

	return {
		download: document => {
			const a = document.createElement('a');
			a.href = url;
			a.target = '_blank';
			a.download = `${name}.${type}`;
			a.click();
		},
		blob: binary,
		url
	};
};

export const getInvoicePdfUrl = (
	customerId,
	invoiceNumber,
	date,
	download = false
) => `${config.API_URL}/api/customers/${customerId}/invoices/${invoiceNumber}/${date}/pdf${download ? '/download' : ''}`;

export const getTransportDocumentPdfUrl = (
	customerId,
	documentNumber,
	date,
	download = false
) => `${config.API_URL}/api/customers/${customerId}/transportDocuments/${documentNumber}/${date}/pdf${download ? '/download' : ''}`;

export const getCustomer = () => get(`/api/me?application=${process.env.REACT_APP_APP_NAME}&version=${process.env.REACT_APP_APP_VERSION}`)
	.then(transformCustomer);
export const getProducts = () => get('/api/products/')
	.then(transformProducts);
export const getPromos = () => get('/api/products/promos');
export const getPoints = () => get('/api/v1/products/points');
export const getSavedPoints = () => get('/api/v1/trade-fairs/savedpointsAndRewards');
export const submitSavedPoints = (body) => post('/api/v1/trade-fairs/savedpoints', JSON.stringify(body));
export const getTradeFairProductPrice = (productId) => get(`/api/v1/products/${productId}/price`);
export const getEobDetails = () => get('/api/v1/products/eobDetails');
export const getCampaigns = () => get('/api/products/campaigns')
	.then(transformCampaigns);
export const getMetadata = () => get('/api/products/metadata');
export const getCustomerMetadata = (customerId) => get(`/api/customers/${customerId}/metadata`);
export const getCustomerProducts = (customerId) => get(`/api/customers/${customerId}/products`)
	.then(transformCustomerProducts);
export const getProductHistory = (customerId) => get(`/api/customers/${customerId}/products/history`);
export const getNewOrder = (customerId) => get(`/api/customers/${customerId}/orders/new`);
export const getOpenOrders = (customerId) => get(`/api/customers/${customerId}/orders/open`)
	.then(transformOpenOrders);
export const getInvoices = (customerId) => get(`/api/customers/${customerId}/invoices`);
export const getTransportDocuments = (customerId) => get(`/api/customers/${customerId}/transportDocuments`)
	.then(transformTransportDocuments);
export const getMarginsMetadata = () => get('/api/customer.manage.suggested.price/metadata');
export const getMargins = (customerId) => get(`/api/customer.manage.suggested.price/${customerId}/margins`);
export const getDetailMargins = (customerId, category, marginType, productType) => get(`/api/customer.manage.suggested.price/${customerId}/margins/categories/${category}/${marginType}/${productType}`);
export const getDetailMarginsByProductId = (customerId, productId) => get(`/api/customer.manage.suggested.price/${customerId}/margins/products/${productId}`);
export const saveMargins = (customerId, actions) => post(`/api/customer.manage.suggested.price/${customerId}/margins`, JSON.stringify({ actions }));
export const getPricechanges = (customerId) => get(`/api/customers/${customerId}/pricechanges`)
	.then(transformPriceChanges);
export const deleteOrder = (customerId, orderId) => post(`/api/customers/${customerId}/orders/${orderId}/delete`);
export const saveOrder = (customerId, orderId, submit, body) => post(`/api/customers/${customerId}/orders/${orderId}${submit ? '/submit' : ''}`, body);
export const removeProductFromBuylist = (customerId, productId) => get(`/api/customers/${customerId}/buylist/${productId}/remove`);
export const addProductToBuylist = (customerId, productId) => get(`/api/customers/${customerId}/buylist/${productId}/add`);
export const getEobNet = (products) => post('/api/products/eob', JSON.stringify({ articles: products }))
	.then(transformEobNet);
export const getVacationDates = (customerId) => get(`/api/customers/${customerId}/vacationperiods`)
	.then(transformVacationDates);
export const submitVacationDates = (dates, customerId) => post(`/api/customers/${customerId}/vacationperiods`, JSON.stringify({ vacationDays: dates }));

export const getPreOrdersData = (customerId, preOrderType) => get(`/api/v1/customers/${customerId}/pre-orders/open?type=${preOrderType}`)//TODO weghalen heir en whitelist in backend
	.then(transformPreOrdersData);

export const submitPreOrder = (customerId, action, preOrderType, final) =>
	post(`/api/customers/${customerId}/pre-orders/${action.orderId}${final ? '/submit' : ''}?type=${preOrderType}`, JSON.stringify(getPreOrderData(action, customerId)));
export const submitAllPreOrdersByType = (customerId, actions, preOrderType) =>
	post(`/api/customers/${customerId}/pre-orders/${preOrderType}/submit?type=${preOrderType}`, JSON.stringify(getPreOrdersSubmitData(actions, customerId)));
export const setCustomer = customer => post('/api/me', JSON.stringify({ customer }));

export const getReports = (customerId) => get(`/api/customers/${customerId}/reports`)
	.then(transformReports);
export const getReportPdfUrl = (
	customerId,
	reportId,
	download = false
) => `${config.API_URL}/api/customers/${customerId}/reports/${reportId}/pdf${download ? '/download' : ''}`;

export const updateSetting = (key, value) => post('/api/me', getSettingsBody(key, value));

const resToString = res => {
	if (res.toString && res.toString() !== '{}') return res.toString();
	if (res.message) return res.message;
	return res;
};

export const get = (endpoint) => method('GET', endpoint);
export const post = (endpoint, body) => method('POST', endpoint, body);

export const method = (method, endpoint, body) => {
	return new Promise((resolve, reject) => {
		try {
			checkMaintenanceMode();
		} catch (error) {
			reject(error);
		}

		const contentType = localStorage.getItem('content-type');

		method = method.toUpperCase();
		const options = {
			credentials: 'include',
			method: method,
			headers: {
				'Content-Type': contentType
			}
		};

		if (method === 'POST' && contentType === 'application/json') {
			options.headers = {
				...(options.headers ?? {}),
				'Content-Type': 'application/json; charset=utf-8'
			};
		}

		let bodyObject = body;

		if (body) {
			options.body = body;

			try {
				bodyObject = JSON.parse(body);
			} catch (error) {
				logger.verbose('body is not JSON, cannot convert', {
					body: body
				});
			}
		}

		fetch(config.API_URL + endpoint, options)
			.then(async res => {
				// Handle session expirations
				if (res.status === 401) {
					let message = '';
					if (localStorage.getItem('portal.client.preferences')) {
						message = t('alert.expired_session');
					}
					// logout with message
					await logout(message);
					if (!window.location.pathname.startsWith('/auth/login')) {
						window.location.href = '/auth/login';
					}

					return reject(res);
				}

				// Handle session expirations
				if (res.status === 403) {
					// TODO: Re-Enable the toast warning after PHP returns are set correctly
					// if (endpoint.indexOf('/api/me') === -1) {
					//     toast.warn(t('alert.forbidden_route'));
					// }

					return reject(res);
				}

				return res.json().then(json => {
					return {
						result: res,
						body: json
					};
				});
			})
			.then(res => {
				if (bodyObject && bodyObject.password) {
					bodyObject.usingPassword = !!bodyObject.password;
					delete bodyObject.password;
				}

				logger.debug(`${method} [${res.result.status}] ${endpoint}`, {
					endpoint: config.API_URL + endpoint,
					body: bodyObject ? bodyObject : 'The request has no body',
					options: options,
					res: res
				});

				if (res.result.status >= 400) {
					const proxyNotAuthenticated = res.result.status === 401 && res.body?.error_code === 1003;
					const notAuthenticated = res.body.code === 401 && res.body.error === 'session_no_auth';

					if (proxyNotAuthenticated || notAuthenticated) {
						if (!window.location.href.includes(config.PORTAL_LOGIN_URL)) {
							window.location.href = config.PORTAL_LOGIN_URL;
						}
					}

					if (res.result.status === 503) {
						const url = new URL(window.location.href);
						const urlParams = new URLSearchParams(window.location.search);
						const maintenance = urlParams.get('maintenance');
						if (!maintenance){
							url.searchParams.append('maintenance', true);
						}
						window.location.href = url;
						logout();

					}
					return reject(res);
				}

				return resolve(res.body);
			})
			.catch(res => {
				if (bodyObject && bodyObject.password) {
					bodyObject.usingPassword = !!bodyObject.password;
					delete bodyObject.password;
				}

				logger.error(`${method} ERROR ${endpoint}`, {
					endpoint: config.API_URL + endpoint,
					body: bodyObject ? bodyObject : 'This request was sent without a body (this is not an error in itself)',
					options: options,
					res: resToString(res)
				});

				return reject(res);
			});
	});
};

const checkMaintenanceMode = () => {
	const urlParams = new URLSearchParams(window.location.search);
	const maintenance = urlParams.get('maintenance');
	if (maintenance || maintenance === '') return new Error('Maintenance mode active');
};

// export const authenticate = (username, password) => {
// 	return new Promise((resolve, reject) => {
// 		post('/api/v1/auth/login', JSON.stringify({
// 			username: username,
// 			password: password
// 		}))
// 			.then(res => {
// 				if (!res?.success) {
// 					return reject(res);
// 				}

// 				// if (res.session && res.session.userId) {
// 				// 	return resolve({
// 				// 		id: res.session.userId,
// 				// 		permissions: []
// 				// 	});
// 				// } else {
// 				// 	return reject(res);
// 				// }
// 				return resolve(res);
// 			})
// 			.catch(reject);
// 	});
// };

export const isAuthenticated = () => {
	return new Promise((resolve, reject) => {
		get('/me')
			.then(resolve)
			.catch(reject);
	});
};

export const logout = (message) => {
	return new Promise((resolve, reject) => {
		// clear cookie on client side
		document.cookie = 'connect.sid=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';

		// clear session on server side
		post('/api/v1/auth/logout')
			.then(res => {
				logger.info('Logout successful', {
					res: res
				});
				localStorage.clear();
				sessionStorage.clear();
				if (message) {
					// a bit hacky, but it works
					localStorage.setItem('auth-exception', message);
				}
				resolve('logged out');
			})
			.catch(res => {
				logger.error('Logout ERROR', {
					res: res
				});
				reject('failed');
			});
	});
};

export default {
	get,
	post,
	method,
	// authenticate,
	isAuthenticated,
	logout,
	getNewOrder,
	getCustomer,
	getOpenOrders,
	getMetadata,
	getCustomerMetadata,
	getProducts,
	getPromos,
	getPoints,
	getSavedPoints,
	getCustomerProducts,
	getProductHistory,
	getInvoices,
	getTransportDocuments,
	getPricechanges,
	getCampaigns,
	deleteOrder,
	saveOrder,
	getInvoicePdfUrl,
	getReports,
	getReportPdfUrl,
	getMarginsMetadata,
	getMargins,
	saveMargins,
	getDetailMargins,
	getDetailMarginsByProductId,
	getEobNet,
	document,
	updateSetting,
	setCustomer,
	getEobDetails,
};

const transformCustomer = (data) => {
	return {
		account: data.account,
		company: data.company,
		customer: data.customer,
		customers: data.customers,
		hasMultipleCustomersAvailable: data.hasMultipleCustomersAvailable === 1 || data.customers.length > 1,
		id: data.id,
		locale: data.language,
		username: data.username,
		permissions: data.roles,
		routes: data.routes,
		latestClientVersion: data?.clients?.find(c => c.application === process.env.REACT_APP_APP_NAME)?.version ?? null,
		settings: {
			SHOW_VACATION_REMINDER: data?.settings?.['remind-vacation-input'] === 1
		}
	};
};

export const transformProduct = (id, p) => {
	return {
		id: id.toString(),
		code: p.code && p.code.toString(),
		brand: p.brand,
		images: p.img,
		specifications: p.specs,
		vvodocs: p.vvodocs,
		shopcode: p.shop,
		weight: p.weight && p.weight.avg,
		group: {
			main: p.mgr,
			sub: p.sgr
		},
		season: p.season === 0 ? false : p.season,
		allergens: p.allergens === 0 ? false : p.allergens,
		name: p.desc,
		description: p.txt,
		orderAmount: p.ppq && p.ppq.req === 1 && p.ppq.qty ? p.ppq.qty : 1,
		start: p.start,
		end: p.end,
		eob: p.eob === 1 ? { net: p.idc?.s } : false,
		timeSensitive: p.tc === 1,
		canOrder: p.co === 0,
		flyers: p.caf ?? [],
		tempNotAvailable: p.tempNotAvailable,
		backAvailableFrom: p.backAvailableFrom
	};
};

const transformProducts = (data) => {
	return Object.fromEntries(Object.entries(data)
		.map(([id, product]) => [
			id,
			transformProduct(id, product)
		]));
};

const transformCustomerProducts = (data) => {
	const customerProducts = {};
	const products = {};
	Object.entries(data).forEach(([id, product]) => {

		customerProducts[id] = {
			id: id.toString(),
			price: product.price,
			unit: product.unity ?? undefined,
			madQuantity: product.mqa,
			pending: product.io === 0 ? false : product.io,
			reserved: product.ir === 0 ? false : product.ir,
			inBuylist: product.fav === 1,
			margin: {
				purchase: {
					price: product.price,
					unit: product.unity,
				},
				value: product.sp?.margin,
				customerPackagedGoods: product.sp?.ecq,
				sales: {
					unit: product.sp?.ade,
					vat: product.sp?.vat,
				},
			}
		};

		if (product.fav === 1 && product.article) {
			products[id] = transformProduct(id, product.article);
		}
	});

	return {
		customerProducts,
		products
	};
};

const transformPriceChanges = priceChanges => priceChanges.map(priceChange => ({
	date: priceChange.date,
	oldPrice: priceChange.oldPrice,
	newPrice: priceChange.newPrice,
	consumerPrice: priceChange.consumerPrice,
	product: transformProduct(priceChange.productId, priceChange.article)
}));

const transformReports = reports => reports.map(report => {
	const getDescription = (date) => {
		// TODO: Make string
		if (date) return `${date?.start} - ${date?.end}`;
		return '';
	};

	const getName = (type, year, month, week) => {
		if (type.type === 'UNKNOWN') return t('reports.unknownReport');
		return t(`reports.reportName_${type.type}_${type.frequency}`, year, month, week);
	};

	const getType = type => {
		switch (type) {
			case 'PRT-001-0A':
				return {
					type: 'BUY_EVOLUTION',
					frequency: 'MONTH'
				};
			case 'PRT-001-0C':
				return {
					type: 'BUY_EVOLUTION',
					frequency: 'WEEK'
				};
			case 'PRT-001-0D':
				return {
					type: 'BUY_EVOLUTION',
					frequency: 'YEAR'
				};
			case 'PRT-001-0E':
				return {
					type: 'RUNNING',
					frequency: 'YEAR'
				};
			default:
				return {
					type: 'UNKNOWN'
				};
		}
	};

	const getSortDate = (report, year, weekOrMonth) => {
		let date;
		switch (report.frequency) {
			case 'w':
				date = DateHelper.getDateOfISOWeek(weekOrMonth, year);
				date.setDate(date.getDate() + 6);
				break;
			case 'm':
				date = new Date(year, weekOrMonth, 0);
				break;
			case 'y':
				date = new Date(year + 1, 0, 1);
				break;
			default:
				date = new Date(0);
				break;
		}

		if (!DateHelper.isValid(date)) return new Date(0);
		return date;
	};

	const [year, weekOrMonth] = report.period.split('.').map(string => parseInt(string));
	const month = report.frequency === 'm' ? t(`datetime.month${weekOrMonth}`) : null;
	const week = report.frequency === 'w' ? weekOrMonth : null;

	const description = getDescription(report.date);
	const type = getType(report.type);
	const sortDate = getSortDate(report, year, weekOrMonth);

	const name = getName(type, year, month, week);
	return {
		id: report.id,
		frequency: report.frequency,
		description,
		type,
		name,
		sortDate
	};
});

const transformTransportDocuments = docs => docs.map(doc => ({ ...doc, number: doc.documentId }));

const transformEobNet = data => {
	const eobNet = {};
	Object.entries(data).forEach(([key, value]) => {
		eobNet[key] = { net: value?.idc?.s ?? null };
	});
	return eobNet;
};

// We follow semver: https://semver.org/
const COMPATIBLE_CAMPAIGNS_VERSION = '^2.0.0';
const transformCampaigns = campaigns =>
	campaigns
		.filter(campaign => semver.satisfies((campaign?.version ?? '1.0.0'), COMPATIBLE_CAMPAIGNS_VERSION))
		.map(campaign => ({ ...campaign.version2 }));

const transformVacationDates = (data) => data?.vacationDays?.map(DateHelper.getDateFromReversedDateString);

const backendSettingsNames = {
	[Setting.SHOW_VACATION_REMINDER]: 'remind-vacation-input'
};

const getSettingsBody = (key, value) => {
	let actualValue = value;
	if (key === Setting.SHOW_VACATION_REMINDER) actualValue = value ? 1 : 0;

	return JSON.stringify({
		settings: {
			[backendSettingsNames[key]]: actualValue
		}
	});
};

const getPreOrderPromo = (promos, action) => {
	if (!promos || !Object.keys(promos)) return undefined;
	const [year, week] = action.delivery.split('.');
	const date = DateHelper.getDateOfISOWeek(week, year);

	const promo = Object.values(promos).find(p => productHelper.isPromoActive(p, date));

	if (!promo) return undefined;

	if (promo.trigger.barrel) {
		return {
			...promo,
			trigger: {
				...promo.trigger,
				barrel: {
					...promo.trigger.barrel,
					articles: promo.trigger.barrel.articles.map(a => transformProduct(a.id, a))
				}
			}
		};
	}

	if (promo.target.article?.id) {
		const article = transformProduct(promo.target.article?.id, promo.target.article);
		promo.target.article = article;
	}

	return promo;
};

const transformPreOrderProduct = (line, action) => {
	const {
		articleId,
		article,
		promo,
		amount_ordered,
		amount_free,
		sort_order
	} = line;
	//const a = transformProduct(articleId, article);

	return {
		//...a,
		id: articleId, //sort, delivery?
		weightNet: article?.weight?.net ?? article?.weight?.avg ?? 0,
		orderedQuantity: amount_ordered,
		freeQuantity: amount_free,
		promo: getPreOrderPromo(promo, action),
		inBuylist: article?.fav === 1, //todo geef mee als article in buylist zit
		sort_order: sort_order
	};
};

const transformPreOrdersData = (data) => {
	return data
		.map(d => ({
			orderId: d.orderId,
			available: d.available,
			delivery: d.delivery,
			description: d.description,
			group: d.group.toString(),
			boothId: d.boothId,
			customNr: d?.customNr,
			...(d.lines.reduce((acc, line) => ({
				quantities: {
					...acc.quantities,
					[line.articleId]: line.amount || 0
				},
				orderedQuantities: {
					...acc.orderedQuantities,
					[line.articleId]: line.amount_ordered || 0
				},
				freeQuantities: {
					...acc.freeQuantities,
					[line.articleId]: line.amount_free || 0
				},
			}), {
				quantities: {}, orderedQuantities: {}, freeQuantities: {}
			})),
			articles: d.lines.map(l => transformPreOrderProduct(l, d)),
			lastSaved: null,
			lastChanged: null,
			section_info: d.section_info
		}));};

export const getPreOrderData = (action, customerId) => {
	const data = {
		companyId: 1,
		//customerId,
		customerId: action?.customNr,
		orderId: action.orderId,
		group: action.group,
		comment: '',
		lines: Object.entries(action.quantities)
			.map(([articleId, amount]) => ({ articleId, amount }))
			.filter(({ amount }) => amount !== 0)
	};

	if (action.boothId) data.boothId = action.boothId;
	if (action.delivery) data.delivery = action.delivery;

	return data;
};

const getPreOrdersSubmitData = (actions, customerId) => {
	const actionsToSave = actions;
	// .filter(action => {
	// if (!action.lastChanged) return false;
	// if (action.lastChanged && !action.lastSaved) return true;
	//return DateHelper.fromReduxDateString(action.lastChanged) > DateHelper.fromReduxDateString(action.lastSaved);
	// });

	//if (actionsToSave.length === 0) actionsToSave.push(actions[0]);

	return actionsToSave.map(action => getPreOrderData(action, customerId)).filter(action => action.lines?.length > 0);
};

const transformOpenOrders = (data) => data.map(order => ({
	...order,
	lines: order.lines.map(line => ({
		...line,
		article: transformProduct(line.articleCode, line.article)
	}))
}));
