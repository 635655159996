import React from 'react';
import { useSelector } from 'react-redux';
import './../../../components/productsViews/productPromo/productPromo.scss';
import promoTypes from '../../products/promoTypes';
import { t } from './../../../libraries/i18n';
import {
	selectActivePromoByProductId
} from './../../../modules/selectors';
import DateHelper from '../../../libraries/dateHelper';
import { FaClock } from 'react-icons/fa';

export default function ProductPromoPreOrder(props) {
	const {
		product,
		hideRibbon,
		view
	} = props;

	//const deliveryDate = useSelector(selectDeliveryDate);
	const deliveryDate = props.deliveryDate;
	const promoDate = deliveryDate;
	//const promo = product.promo;
	//TO DO: if not 40-48
	let promo = useSelector(state => selectActivePromoByProductId(state, product.id, promoDate));
	// TODO: Don't use the store promo, if the product promo has more info (?)
	promo = product.promo || promo;
	const renderDuration = (promo) => {
		let delayedPromo = false; // Set true for promos like 40-48

		if ( props.preOrderType==='5'){
			delayedPromo=true;
		}

		const dates = {
			available: {
				start: DateHelper.getDateString(new Date(promo.available.start)),
				end: DateHelper.getDateString(new Date(promo.available.end))
			},
			visible: {
				start: DateHelper.getDateString(new Date(promo.visible.start)),
				end: DateHelper.getDateString(new Date(promo.visible.end))
			}
		};

		const topText = t('promo.durationDelayedOrderDate', dates.visible.start, dates.visible.end);
		const bottomText = t(delayedPromo ? 'promo.durationDelayedDeliveryDate' : 'promo.duration', dates.available.start, dates.available.end);

		return (
			<div className={'promo__duration'}>
				<div className={'duration__icon'}>
					<FaClock />
				</div>
				<div className={'duration__text'}>
					{delayedPromo && (
						<div className={'text__top'} dangerouslySetInnerHTML={{ __html: topText }} />
					)}
					<div className={'text__bottom'} dangerouslySetInnerHTML={{ __html: bottomText }} />
				</div>
			</div>
		);
	};

	const renderBasicDescription = promo => {
		const renderPromoPercentDiscount = promo => promo.trigger.num === 1
			? t('order.promoPercentDiscount', promo.target.num)
			: t('order.promoPercentDiscountMultiple', promo.target.num, promo.trigger.num);

		const renderPromoFreeByUnit = (promo) => {
			if (promo.trigger.id === promo.target.id) return t('order.promoFreeByUnit', promo.trigger.num, promo.target.num);

			const targetProduct = promo?.target?.product;
			return promo.trigger.num === 1
				? t('order.promoFreeByUnitDifferentTarget', promo.target.num, targetProduct?.name)
				: t('order.promoFreeByUnitDifferentTargetMultiple', promo.trigger.num, promo.target.num, targetProduct?.name, promo.trigger?.name, promo.trigger?.name, promo.trigger?.id);
		};

		const renderPromoFixedPrice = (promo) => {
			const targetNum = promo.target?.num.toString().replace('.', ',');

			return promo.trigger.num === 1
				? t(`order.promoFixedPrice${promo.trigger?.unit}`, targetNum)
				: t(`order.promoFixedPriceMultiple${promo.trigger?.unit}`, targetNum, promo.trigger.num);
		};

		const renderPromoFree = (promo) => promo.target.num === 1
			? t('order.promoFree')
			: t('order.promoFreeMultiple');

		const renderPromoPricePerWeight = (promo) => promo.trigger.num === 1
			? t('order.promoPricePerWeight', promo.target.num.toString().replace('.', ','))
			: t('order.promoPricePerWeightMultiple', promo.trigger.num, promo.target.num.toString().replace('.', ','));

		const renderPromoPricePerUnit = (promo) => promo.trigger.num === 1
			? t('order.promoPricePerUnit', promo.target.num.toString().replace('.', ','))
			: t('order.promoPricePerUnitMultiple', promo.trigger.num, promo.target.num.toString().replace('.', ','));

		const RENDERERS = {
			[promoTypes.PERCENT_DISCOUNT]: renderPromoPercentDiscount,
			[promoTypes.FIXED_PRICE]: renderPromoFixedPrice,
			[promoTypes.FREE_BY_UNIT]: renderPromoFreeByUnit,
			[promoTypes.PRICE_PER_UNIT]: renderPromoPricePerUnit,
			[promoTypes.PRICE_PER_WEIGHT]: renderPromoPricePerWeight,
			[promoTypes.FREE]: renderPromoFree
		};
		const renderer = RENDERERS[promo.type];
		if (!renderer) return null;

		return renderer(promo);
	};

	const renderColliDescription = promo => {
		const renderColliProducts = promo => (
			<div className={'colli__products'}>
				{t('order.promoParticipatingProducts')}

				{promo.trigger?.barrel.articles.map(product => (
					product && (
						<div key={product.code} className={'colli__product'}>
							<span className={'product__code'}>{product.code}:</span> {product.name}
						</div>
					)
				))}
			</div>
		);

		const targetProduct = promo.target?.product;

		const description = t(
			`promo.label_promo_colli_${promo.type}`,
			promo.trigger.num,
			promo.trigger.barrel.num,
			parseInt(promo.target.num),
			`${targetProduct?.code}: ${targetProduct?.desc}`
		);

		return (
			<>
				{description}
				{promo.trigger?.barrel?.articles && renderColliProducts(promo)}
			</>
		);
	};

	const renderDescription = promo => {
		if (promo.trigger.barrel) {
			if (view !== 'detail') return t('promo.colliShortDescription');
			return renderColliDescription(promo);
		}

		return renderBasicDescription(promo);
	};

	const renderBody = (promo) => {
		const description = (
			<div className='promo__description'>
				{renderDescription(promo)}
			</div>
		);
		if (view !== 'detail') return description;

		return (
			<div className={'promo__body'}>
				{description}
				{renderDuration(promo)}
			</div>
		);
	};

	if (!promo || !product || !promoDate) return null;
	if (promo.trigger.colli && promo.target.id === product.id) return null;

	return (
		<div className={'ProductPromo'}>
			<div className={`promo__ribbon ${hideRibbon ? 'hide' : ''}`}>
				{promo.title !== '' ? promo.title : t('order.promoLabel')}
			</div>

			{renderBody(promo)}
		</div>
	);
}
