import DateHelper from './../../libraries/dateHelper';
import Api from './../../libraries/api';
import { getOrderProducts } from './updateOrderProducts';
import {
	selectOrderDetails,
	selectCustomerId,
	selectProductItems,
	selectOrderLastSaved,
	selectFailedOrderSaveAttempts,
	selectIsEobFetching,
	selectReviewOrderProducts,
	selectIsOpenOrdersFetching,
	selectIsOrderFromServer,
	selectOrderId
} from './../selectors';
import { Gtag } from '../common/utils';

export const ORDER_MODULE_LOADED = 'ORDER_MODULE_LOADED';
export const ORDER_DELIVERY_DATE_SELECT = 'ORDER_DELIVERY_DATE_SELECT';
export const ORDER_DELIVERY_DATES_REQUEST = 'ORDER_DELIVERY_DATES_REQUEST';
export const ORDER_DELIVERY_DATES_RECEIVE = 'ORDER_DELIVERY_DATES_RECEIVE';
export const ORDER_DELIVERY_DATES_INVALIDATE = 'ORDER_DELIVERY_DATES_INVALIDATE';
export const ORDER_DELIVERY_DATES_REJECT = 'ORDER_DELIVERY_DATES_REJECT';
export const ORDER_UPDATE_PRODUCT_QUANTITY = 'ORDER_UPDATE_PRODUCT_QUANTITY';
export const ORDER_PRODUCT_SELECTION_HANDLE_PAGINATION = 'ORDER_PRODUCT_SELECTION_HANDLE_PAGINATION';
export const ORDER_PRODUCT_SELECTION_HANDLE_SEARCH = 'ORDER_PRODUCT_SELECTION_HANDLE_SEARCH';
export const ORDER_PRODUCT_SELECTION_CHANGE_VIEW_TYPE = 'ORDER_PRODUCT_SELECTION_CHANGE_VIEW_TYPE';
export const ORDER_PRODUCT_SELECTION_TOGGLE_FILTER = 'ORDER_PRODUCT_SELECTION_TOGGLE_FILTER';
export const ORDER_PRODUCT_SELECTION_SET_FILTER = 'ORDER_PRODUCT_SELECTION_SET_FILTER';
export const ORDER_PRODUCT_SELECTION_CLEAR_FILTERS = 'ORDER_PRODUCT_SELECTION_CLEAR_FILTERS';
export const ORDER_PRODUCT_SELECTION_INFINITE_SCROLL_END_REACHED = 'ORDER_PRODUCT_SELECTION_INFINITE_SCROLL_END_REACHED';
export const ORDER_PRODUCT_SELECTION_FOCUS_PRODUCT = 'ORDER_PRODUCT_SELECTION_FOCUS_PRODUCT';
export const ORDER_REMARKS_UPDATE_REFERENCE = 'ORDER_REMARKS_UPDATE_REFERENCE';
export const ORDER_REMARKS_UPDATE_COMMENT = 'ORDER_REMARKS_UPDATE_COMMENT';
export const ORDER_PRODUCT_SELECTION_AWARD_PROMO_IF_ELIGIBLE = 'ORDER_PRODUCT_SELECTION_AWARD_PROMO_IF_ELIGIBLE';
export const ORDER_LOAD_EXISTING_ORDER = 'ORDER_LOAD_EXISTING_ORDER';
export const ORDER_CONTINUE_WITH_ORDER_FROM_SERVER = 'ORDER_CONTINUE_WITH_ORDER_FROM_SERVER';
export const ORDER_NEW = 'ORDER_NEW';
export const ORDER_NEW_DETAILS = 'ORDER_NEW_DETAILS';
export const ORDER_RESET = 'ORDER_RESET';
export const ORDER_TOGGLE_SHOW_SCORES = 'ORDER_TOGGLE_SHOW_SCORES';
export const ORDER_OPEN_REQUEST = 'ORDER_OPEN_REQUEST';
export const ORDER_OPEN_RECEIVE = 'ORDER_OPEN_RECEIVE';
export const ORDER_PRODUCT_SELECTION_SET_SHOPCODE_FILTER = 'ORDER_PRODUCT_SELECTION_SET_SHOPCODE_FILTER';
export const ORDER_SAVE_REQUEST = 'ORDER_SAVE_REQUEST';
export const ORDER_SAVE_RECEIVE = 'ORDER_SAVE_RECEIVE';
export const ORDER_SAVE_REJECT = 'ORDER_SAVE_REJECT';
export const ORDER_PRODUCT_SELECTION_CHANGE_SORTING = 'ORDER_PRODUCT_SELECTION_CHANGE_SORTING';
export const ORDER_DELETE_PRODUCT_FROM_ORDER = 'ORDER_DELETE_PRODUCT_FROM_ORDER';
export const ORDER_CONFIRM_MAD_QUANTITY = 'ORDER_CONFIRM_MAD_QUANTITY';
export const ORDER_UPDATE_ORDER_PRODUCTS = 'ORDER_UPDATE_ORDER_PRODUCTS';
export const ORDER_UPDATE_LAST_VISITED_STEP = 'ORDER_UPDATE_LAST_VISITED_STEP';
export const ORDER_SET_ANSWERS = 'ORDER_SET_ANSWERS';
export const ORDER_NEW_REJECT = 'ORDER_NEW_REJECT';
export const ORDER_EOB_NET_REQUEST = 'ORDER_EOB_NET_REQUEST';
export const ORDER_EOB_NET_RECEIVE = 'ORDER_EOB_NET_RECEIVE';
export const ORDER_EOB_NET_REJECT = 'ORDER_EOB_NET_REJECT';

export function setDeliveryDate(date) {
	return (dispatch) => {
		dispatch(updateOrderProducts({ deliveryDate: date }));
		dispatch({
			type: ORDER_DELIVERY_DATE_SELECT,
			payload: date
		});
	};
}

export function invalidateDeliveryDates() {
	return {
		type: ORDER_DELIVERY_DATES_INVALIDATE
	};
}

function requestDeliveryDates() {
	return {
		type: ORDER_DELIVERY_DATES_REQUEST
	};
}

function receiveDeliveryDates(data) {
	return {
		type: ORDER_DELIVERY_DATES_RECEIVE,
		data: data,
		receivedAt: Date.now()
	};
}

function rejectDeliveryDates(error) {
	return {
		type: ORDER_DELIVERY_DATES_REJECT,
		error: {
			message: `Fout bij het ophalen van de leverdagen door de bestelmodule: ${error.message}`,
			receivedAt: Date.now()
		}
	};
}

export function updateProductQuantityAction(product, { quantity, timestamp }) {
	return {
		type: ORDER_UPDATE_PRODUCT_QUANTITY,
		product: product,
		quantity: quantity,
		timestamp: timestamp
	};
}

export function handlePagination(page) {
	return {
		type: ORDER_PRODUCT_SELECTION_HANDLE_PAGINATION,
		page: page
	};
}

export function handleSearch(query) {
	return {
		type: ORDER_PRODUCT_SELECTION_HANDLE_SEARCH,
		query: query
	};
}

export function changeProductView(view) {
	Gtag.event({
		category: 'ProductsView',
		action: view
	});
	return {
		type: ORDER_PRODUCT_SELECTION_CHANGE_VIEW_TYPE,
		view: view
	};
}

export function toggleFilter(filter) {
	Gtag.event({
		category: 'ToggleFilter',
		action: filter
	});
	return {
		type: ORDER_PRODUCT_SELECTION_TOGGLE_FILTER,
		filter: filter
	};
}

export function setFilter(filter, state) {
	return {
		type: ORDER_PRODUCT_SELECTION_SET_FILTER,
		filter: filter,
		state: state
	};
}

export function clearFilters(group) {
	Gtag.event({
		category: 'ClearFilters',
		action: group
	});

	return {
		type: ORDER_PRODUCT_SELECTION_CLEAR_FILTERS,
		group: group
	};
}

export function infiniteScrollEndReached() {
	return {
		type: ORDER_PRODUCT_SELECTION_INFINITE_SCROLL_END_REACHED
	};
}

export function setProductFocus(id) {
	return {
		type: ORDER_PRODUCT_SELECTION_FOCUS_PRODUCT,
		id: id
	};
}

export function updateReference(reference) {
	return {
		type: ORDER_REMARKS_UPDATE_REFERENCE,
		reference: reference
	};
}

export function updateComment(comment) {
	return {
		type: ORDER_REMARKS_UPDATE_COMMENT,
		comment: comment
	};
}

export function awardPromoIfEligible(product, quantity, promo) {
	return {
		type: ORDER_PRODUCT_SELECTION_AWARD_PROMO_IF_ELIGIBLE,
		product: product,
		quantity: quantity,
		promo: promo
	};
}

function loadOrder(order) {
	return {
		type: ORDER_LOAD_EXISTING_ORDER,
		payload: order
	};
}

export function continueWithOrderFromServer() {
	return {
		type: ORDER_CONTINUE_WITH_ORDER_FROM_SERVER
	};
}

function newOrder() {
	return {
		type: ORDER_NEW
	};
}

function receiveNewOrderDetails(orderId, companyId) {
	return {
		type: ORDER_NEW_DETAILS,
		orderId: orderId,
		companyId: companyId
	};
}

export function resetOrderModule() {
	return {
		type: ORDER_RESET
	};
}

export function toggleShowFilterScores() {
	return {
		type: ORDER_TOGGLE_SHOW_SCORES
	};
}

function requestOpenOrders() {
	return {
		type: ORDER_OPEN_REQUEST
	};
}

function receiveOpenOrders() {
	return {
		type: ORDER_OPEN_RECEIVE
	};
}

export function setShopcodeFilter(shopcode) {
	return {
		type: ORDER_PRODUCT_SELECTION_SET_SHOPCODE_FILTER,
		payload: shopcode
	};
}

function saveOrderRequest(final = false) {
	return {
		type: ORDER_SAVE_REQUEST,
		payload: final
	};
}

export function setOrderAnswers(answers) {
	return {
		type: ORDER_SET_ANSWERS,
		payload: answers
	};
}

function saveOrderReceive(saveTime, submit, warnings, questions, reminders, info, issues) {
	return {
		type: ORDER_SAVE_RECEIVE,
		payload: {
			saveTime,
			submit,
			warnings,
			questions,
			reminders,
			info,
			issues
		}
	};
}

function saveOrderReject(response) {
	return {
		type: ORDER_SAVE_REJECT,
		response
	};
}

export function changeSorting(sortKey, direction) {
	return {
		type: ORDER_PRODUCT_SELECTION_CHANGE_SORTING,
		payload: {
			sortKey,
			direction
		}
	};
}

export function deleteProductFromOrder(product) {
	return {
		type: ORDER_DELETE_PRODUCT_FROM_ORDER,
		payload: product
	};
}

export function confirmMadQuantity(product) {
	Gtag.event({
		category: 'MadQuantity',
		action: 'Confirm'
	});

	return {
		type: ORDER_CONFIRM_MAD_QUANTITY,
		product: product
	};
}

export function addPromoProductsToOrder(products) {
	return dispatch => {
		products.forEach(product => {
			dispatch(updateProductQuantity(product, product.quantity));
		});

		dispatch(clearFilters());
		dispatch(setFilter('inOrder', 'include'));
	};
}

export function createNewOrderAndDiscardOpenOrders() {
	return (dispatch, getState) => {
		const state = getState();
		const customerId = selectCustomerId(state);

		// Discard open order
		Api.deleteOrder(customerId, state.order.orderId);

		// Create new order
		dispatch(createNewOrder(customerId));
	};
}

export function createNewOrder(customerId) {
	return async dispatch => {
		dispatch(newOrder());

		try {
			const order = await Api.getNewOrder(customerId);
			dispatch(receiveNewOrderDetails(order.nr, order.companyId));
		} catch (error) {
			dispatch({ type: ORDER_NEW_REJECT });
		}
	};
}

export function updateProductQuantity(product, quantity, promo) {
	return dispatch => {
		dispatch(updateProductQuantityAction(product, quantity));
		if (promo) dispatch(awardPromoIfEligible(product, quantity.quantity, promo));
	};
}

function isOrderEmpty(order) {
	return !order.deliveryDate &&
		order.lines.length === 0 &&
		!order.comment &&
		!order.reference;
}

const shouldCreateOrLoadOrder = (state) => {
	const isFetching = selectIsOpenOrdersFetching(state);
	const hasOpenOrderFromServer = selectIsOrderFromServer(state);
	const orderId = selectOrderId(state);

	return !isFetching && !hasOpenOrderFromServer && orderId === null;
};

export function createOrLoadOrderIfNeeded(customerId) {
	return (dispatch, getState) => {
		if (shouldCreateOrLoadOrder(getState())) {
			return dispatch(createOrLoadOrder(customerId));
		}
	};
}

function createOrLoadOrder(customerId) {
	return dispatch => {
		dispatch(requestOpenOrders());
		Api.getOpenOrders(customerId)
			.then(orders => {
				dispatch(receiveOpenOrders());
				if (!orders || orders.length === 0) {
					dispatch(createNewOrder(customerId));
				} else {
					const order = orders[orders.length - 1]; // TODO: display multiple open orders
					dispatch(loadOrderFromServer(order, isOrderEmpty(order)));
				}
			});
	};
}

function loadOrderFromServer(serverOrder, emptyOrder) {
	return dispatch => {
		const orderId = serverOrder.nr;
		const selected = serverOrder.deliveryDate ? DateHelper.getDateFromReversedDateString(serverOrder.deliveryDate) : null;
		const products = {};
		serverOrder.lines.forEach(line => {
			products[line.articleCode] = line.article.orderAmount ? line.amount / line.article.orderAmount : line.amount;
		});
		const remarks = {};
		remarks.reference = serverOrder.reference;
		remarks.comment = serverOrder.comment;
		const companyId = serverOrder.companyId;

		dispatch(loadOrder({
			orderId,
			selected,
			products,
			remarks,
			companyId,
			emptyOrder,
			modifiedBy: serverOrder.modifiedBy
		}));
	};
}

export function saveOrder(submitOrder = false) {
	return (dispatch, getState) => {
		const state = getState();
		const details = selectOrderDetails(state);
		const customerId = selectCustomerId(state);

		const answersNotUnanswered = Object.entries(details.answers).filter(([_, answer]) => (answer ?? false));
		const confirmations = answersNotUnanswered.map(([code, answer]) => ({ answer, code }));

		const deliveryDate = DateHelper.getReversedDateString(details.deliveryDate);
		const lines = Object.keys(details.quantities).map(id => {
			const product = selectProductItems(state)[id]; // make selector for this
			const quantity = details.quantities[id];

			return {
				articleCode: id,
				amount: product?.orderAmount ? quantity * product.orderAmount : quantity || 0
			};
		}).filter(line => line.amount !== 0 && line.amount !== null);
		const {
			reference,
			comment,
			orderId,
			companyId
		} = details;
		const postDetails = {
			reference,
			comment,
			lines,
			deliveryDate,
			orderId,
			companyId,
			submitOrder,
			confirmations
		};

		dispatch(saveOrderRequest(submitOrder));
		const saveTime = Date.now();
		Api.saveOrder(selectCustomerId(state), postDetails.orderId, submitOrder, JSON.stringify(postDetails))
			.then(result => {
				if (result.success && !result?.issues?.length) {
					dispatch(saveOrderReceive(saveTime, submitOrder, result.warnings, result.questions, result.reminders, result.info, result.issues));
					if (submitOrder) dispatch(createNewOrder(customerId));
				} else {
					dispatch(saveOrderReceive(saveTime, submitOrder, result.warnings, result.questions, result.reminders, result.info, result.issues));
					//dispatch(saveOrderReceive(saveTime, submitOrder));
				}
			})
			.catch(result => {
				dispatch(saveOrderReject(result));
			});
	};
}

export function saveOrderIfNeeded(submit = false, forceItAnyway = false) {
	return (dispatch, getState) => {
		if (shouldSaveOrder(getState(), submit)) {
			return dispatch(saveOrder(submit));
		} else if (forceItAnyway) {
			return dispatch(saveOrder(false));
		}
	};
}

function shouldSaveOrder(state, submit) {
	const order = state.order;

	if (state.products.isFetching
		|| order.deliveryDates.isFetching
		|| order.isOpenOrdersFetching
		|| state.products.isCustomerProductsFetching) return false;

	if (order.isSaving) return false;
	if (submit) return true;
	if (order.didSaveFail) {
		const lastSaved = selectOrderLastSaved(state);
		const failedAttempts = selectFailedOrderSaveAttempts(state);
		const now = Date.now();
		const delta = now - lastSaved;

		const MAX_TIME_BETWEEN_ATTEMPT = 60_000;
		const minTimeBetweenAttempts = Math.min(Math.pow(2, failedAttempts) * 1000, MAX_TIME_BETWEEN_ATTEMPT);

		return delta > minTimeBetweenAttempts;
	}
	if (order.lastUpdated === 0) return false;

	return order.lastUpdated > order.lastSaved;
}

function shouldTryForNewOrderId(state) {
	if (state.order.orderId) return false;
	if (state.order.isFetchingOrderId) return false;

	const failedAttempts = state.order.newOrderAttempts;
	if (!failedAttempts || failedAttempts <= 0) return false;

	const now = Date.now();
	const lastTry = state.order.lastNewOrderIdTry;
	const delta = now - lastTry;

	const MAX_TIME_BETWEEN_ATTEMPTS = 60_000;
	const minTimeBetweenAttempts = Math.min(Math.pow(2, failedAttempts) * 1000, MAX_TIME_BETWEEN_ATTEMPTS);

	return delta > minTimeBetweenAttempts;
}

export function tryForOrderIdIfNeeded() {
	return (dispatch, getState) => {
		const state = getState();
		const customerId = selectCustomerId(state);

		if (shouldTryForNewOrderId(state)) return dispatch(createNewOrder(customerId));
	};
}

function fetchDeliveryDates(customerId) {
	return dispatch => {
		dispatch(requestDeliveryDates());

		return Api.getCustomerMetadata(customerId)
			.then(data => {
				return {
					...data,
					deliveryDates: data.deliveryDates
						.map((deliveryDate) => ({
							date: DateHelper.getDateStringFromReversedDateString(deliveryDate.deliveryDate),
							isDefault: !!deliveryDate.isDefaultDeliveryDate,
							deadline: deliveryDate.orderSubmitDeadline,
							isDivergentDeadline: !!deliveryDate.isDivergentOrderSubmitDeadline

						}))
						.sort((a, b) =>
							DateHelper.getDateFromDateString(a.date) > DateHelper.getDateFromDateString(b.date)
						)
				};
			})
			.then(data => dispatch(receiveDeliveryDates(data)))
			.catch(res => dispatch(rejectDeliveryDates(res)));
	};
}

function shouldFetchDeliveryDates(state) {
	const deliveryDates = state.order.deliveryDates;
	if (deliveryDates.isFetching) {
		return false;
	} else if (deliveryDates.items.length <= 0) {
		return true;
	} else {
		return deliveryDates.didInvalidate;
	}
}

export function fetchDeliveryDatesIfNeeded(customerId) {
	return (dispatch, getState) => {
		if (shouldFetchDeliveryDates(getState())) {
			return dispatch(fetchDeliveryDates(customerId));
		}
	};
}

export const updateOrderProducts = data => {
	return (dispatch, getState) => {
		const products = getOrderProducts(getState(), data);
		dispatch({
			type: ORDER_UPDATE_ORDER_PRODUCTS,
			products
		});
	};
};

export const updateLastVisitedOrderStep = route => {
	return {
		type: ORDER_UPDATE_LAST_VISITED_STEP,
		payload: route
	};
};

export const doubleCheckInvalidEobNetDates = (products) => {
	return (dispatch) => {
		const idsOfProductsWithInvalidNetDate = products
			.filter(p => {
				if (!p.eob) return false;
				if (!p.eob.net) return true;
				const net = (new Date(p.eob.net)).getTime();
				const now = (new Date()).getTime();
				return now >= net;
			})
			.map(p => p.id);

		if (idsOfProductsWithInvalidNetDate.length > 0) dispatch(fetchEobNetForProducts(idsOfProductsWithInvalidNetDate));
	};
};

const shouldFetchEobNet = (state) => {
	const isFetching = selectIsEobFetching(state);
	return !isFetching;
};

export const fetchEobNetForProductsInOrderIfNeeded = () => {
	return (dispatch, getState) => {
		const state = getState();
		const products = selectReviewOrderProducts(state);
		const eobIds = products
			.filter(p => p.eob)
			.map(p => p.id);

		dispatch(fetchEobNetIfNeeded(eobIds));
	};
};

export const fetchEobNetIfNeeded = products => {
	return (dispatch, getState) => {
		const state = getState();
		if (shouldFetchEobNet(state)) dispatch(fetchEobNetForProducts(products));
	};
};

export const fetchEobNetForProducts = products => {
	return async dispatch => {
		dispatch({ type: ORDER_EOB_NET_REQUEST });

		try {
			const result = await Api.getEobNet(products);
			dispatch({ type: ORDER_EOB_NET_RECEIVE, payload: result });
			dispatch(updateOrderProducts({ eobNet: result }));
		} catch (error) {
			dispatch({
				type: ORDER_EOB_NET_REJECT,
				payload: error
			});
		}
	};
};
